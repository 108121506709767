export const usoCfdi = [
    {
        code: 'G01',
        description: 'Adquisición de mercancías.',
        applicable: new Set([601, 603, 606, 612, 620, 621, 622, 623, 624, 625, 626]),
    },
    {
        code: 'G02',
        description: 'Devoluciones, descuentos o bonificaciones.',
        applicable: new Set([601, 603, 606, 612, 620, 621, 622, 623, 624, 625, 626]),
    },
    {
        code: 'G03',
        description: 'Gastos en general.',
        applicable: new Set([601, 603, 606, 612, 620, 621, 622, 623, 624, 625, 626]),
    },
    {
        code: 'I01',
        description: 'Construcciones.',
        applicable: new Set([601, 603, 606, 612, 620, 621, 622, 623, 624, 625, 626]),
    },
    {
        code: 'I02',
        description: 'Mobiliario y equipo de oficina por inversiones.',
        applicable: new Set([601, 603, 606, 612, 620, 621, 622, 623, 624, 625, 626]),
    },
    {
        code: 'I03',
        description: 'Equipo de transporte.',
        applicable: new Set([601, 603, 606, 612, 620, 621, 622, 623, 624, 625, 626]),
    },
    {
        code: 'I04',
        description: 'Equipo de computo y accesorios.',
        applicable: new Set([601, 603, 606, 612, 620, 621, 622, 623, 624, 625, 626]),
    },
    {
        code: 'I05',
        description: 'Dados, troqueles, moldes, matrices y herramental.',
        applicable: new Set([601, 603, 606, 612, 620, 621, 622, 623, 624, 625, 626]),
    },
    {
        code: 'I06',
        description: 'Comunicaciones telefónicas.',
        applicable: new Set([601, 603, 606, 612, 620, 621, 622, 623, 624, 625, 626]),
    },
    {
        code: 'I07',
        description: 'Comunicaciones satelitales.',
        applicable: new Set([601, 603, 606, 612, 620, 621, 622, 623, 624, 625, 626]),
    },
    {
        code: 'I08',
        description: 'Otra maquinaria y equipo.',
        applicable: new Set([601, 603, 606, 612, 620, 621, 622, 623, 624, 625, 626]),
    },
    {
        code: 'D01',
        description: 'Honorarios médicos, dentales y gastos hospitalarios.',
        applicable: new Set([605, 606, 608, 611, 612, 614, 607, 615, 625]),
    },
    {
        code: 'D02',
        description: 'Gastos médicos por incapacidad o discapacidad.',
        applicable: new Set([605, 606, 608, 611, 612, 614, 607, 615, 625]),
    },
    {
        code: 'D03',
        description: 'Gastos funerales.',
        applicable: new Set([605, 606, 608, 611, 612, 614, 607, 615, 625]),
    },
    {
        code: 'D04',
        description: 'Donativos.',
        applicable: new Set([605, 606, 608, 611, 612, 614, 607, 615, 625]),
    },
    {
        code: 'D05',
        description:
            'Intereses reales efectivamente pagados por créditos hipotecarios (casa habitación).',
        applicable: new Set([605, 606, 608, 611, 612, 614, 607, 615, 625]),
    },
    {
        code: 'D06',
        description: 'Aportaciones voluntarias al SAR.',
        applicable: new Set([605, 606, 608, 611, 612, 614, 607, 615, 625]),
    },
    {
        code: 'D07',
        description: 'Primas por seguros de gastos médicos.',
        applicable: new Set([605, 606, 608, 611, 612, 614, 607, 615, 625]),
    },
    {
        code: 'D08',
        description: 'Gastos de transportación escolar obligatoria.',
        applicable: new Set([605, 606, 608, 611, 612, 614, 607, 615, 625]),
    },
    {
        code: 'D09',
        description:
            'Depósitos en cuentas para el ahorro, primas que tengan como base planes de pensiones.',
        applicable: new Set([605, 606, 608, 611, 612, 614, 607, 615, 625]),
    },
    {
        code: 'D10',
        description: 'Pagos por servicios educativos (colegiaturas).',
        applicable: new Set([605, 606, 608, 611, 612, 614, 607, 615, 625]),
    },
    {
        code: 'S01',
        description: 'Sin efectos fiscales.',
        applicable: new Set([
            601, 603, 605, 606, 608, 610, 611, 612, 614, 616, 620, 621, 622, 623, 624,
            607, 615, 625, 626,
        ]),
    },
    {
        code: 'CP01',
        description: 'Pagos',
        applicable: new Set([
            601, 603, 605, 606, 608, 610, 611, 612, 614, 616, 620, 621, 622, 623, 624,
            607, 615, 625, 626,
        ]),
    },
    {
        code: 'CN01',
        description: 'Nómina',
        applicable: new Set([605]),
    },
] as const

export type UsoCfdiKey = (typeof usoCfdi)[number]['code']
