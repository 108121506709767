import { FC, useMemo } from 'react'
import cx from 'classnames'

import styles from './style.module.scss'

const colors = [
    '#72a0c2',
    '#83b4d8',
    '#f3a179',
    '#ead7e9',
    '#73c7af',
    '#df9028',
    '#c1b2df',
    '#599855',
    '#534f6d',
    '#9e759c',
    '#faeb7a',
]

type Props = {
    name: string
}

export const Avatar: FC<Props> = ({ name }) => {
    const { display, backgroundColor } = useMemo(() => {
        if (!name?.trim()) return { display: '', backgroundColor: colors[0] }
        const firstLetter = name.toUpperCase()[0]
        const colorIndex = firstLetter.charCodeAt(0) % colors.length

        return { display: firstLetter, backgroundColor: colors[colorIndex] }
    }, [name])

    return (
        <div
            className={cx('inline inline-md', styles.avatar)}
            style={{ backgroundColor }}
        >
            {display}
        </div>
    )
}
