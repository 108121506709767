import { InvoiceV2 } from 'lib/models/invoice'
import { PaginationMetadata } from 'lib/models/paginationMetadata'
import { downloadFile, makeApiRequest } from 'lib/utils/apiUtils/makeApiRequest'
import { formatDateTimestamp } from 'lib/utils/formatDateTimestamp'

const url = '/invoices'

export const invoiceService = {
    getInvoices: async (queryParams: GetInvoicesParams) => {
        if (queryParams.download) {
            const filename = `cobros-${formatDateTimestamp(new Date())}.csv`
            return downloadFile({ url, queryParams, filename })
        }
        return makeApiRequest<GetInvoicesData>({ url, queryParams })
    },
    getInvoice: async ({ invoice_id }) => {
        if (!invoice_id) return false
        return makeApiRequest({ url: `${url}/${invoice_id}` })
    },
    getInvoiceDeletionEligibility: async (invoice_id: number) =>
        makeApiRequest<GetInvoiceDeletionEligibilityData>({
            url: `${url}/${invoice_id}/deletion-eligibility`,
        }),
    postInvoice: async (body) => makeApiRequest({ url, method: 'POST', body }),
    putInvoice: async (body) =>
        makeApiRequest({
            url: `${url}/${body.invoice_id}`,
            method: 'PUT',
            body,
        }),
    postInvoicePaymentPlan: async (body) =>
        makeApiRequest({
            url: `${url}/${body.invoice_id}/payment-plan`,
            method: 'POST',
            body,
        }),
    postRetryFailedAutoChargePayment: async (body) =>
        makeApiRequest({
            url: `${url}/retry-payment`,
            method: 'POST',
            body,
        }),
    deleteInvoice: async (body: DeleteInvoiceBody) =>
        makeApiRequest({
            url: `${url}/${body.invoice_id}`,
            method: 'DELETE',
            body,
        }),
    deleteInvoices: async (body) =>
        makeApiRequest({ url: `${url}/bulk-delete`, method: 'DELETE', body }),
    downloadInvoicePdf: ({ invoice_id, series, invoice_num, settled_date }) => {
        const seriesAndInvoiceNum = [series, invoice_num].filter(Boolean).join(' ')
        const filename = `${
            settled_date ? 'Recibo' : 'Nota de venta'
        } - Folio ${seriesAndInvoiceNum}.pdf`
        return downloadFile({
            url: `${url}/${invoice_id}/sales-order`,
            queryParams: { download: true },
            filename,
        })
    },
    getInvoicePdfUrl: ({ invoice_id }) =>
        makeApiRequest({ url: `${url}/${invoice_id}/sales-order` }),
}

type GetInvoicesData = {
    paginationMetadata: PaginationMetadata
    invoices: InvoiceV2[]
}

type DeleteInvoiceBody = {
    invoice_id: number
    bypass_cfdi_check: boolean
}

type GetInvoiceDeletionEligibilityData = {
    can_delete: boolean
    needs_bypass: boolean
}

type GetInvoicesParams = {
    download?: boolean
    components?: string
    customer_id?: number | string
    invoice_ids?: number[]
    sort_by?: string
    limit?: number
    offset?: number
    settled_state?: string | null
    search?: string
    cfdi_i_payment_method?: string | null
    date_range_param?: string | null
    organization_id?: number | string | null
    currency?: string | null
    start?: Date | null
    end?: Date | null
    tag_ids?: number[] | null
    product_id?: number | null
    subscription_id?: number | null
}
