import { FC, useMemo, useState } from 'react'
import { toast } from 'react-toastify'
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'

import {
    Badge,
    Banner,
    Button,
    ErrorMessage,
    Icon,
    Modal,
    Spinner,
    Table,
    Tooltip,
} from 'ds/components'

import { getErrorFromQuery } from 'lib/utils/getErrorFromQuery'
import { mutationOnError, mutationOnSuccess } from 'lib/utils/mutationUtils'
import { notificationService } from 'lib/services/notificationService'
import { shortenStringMiddle } from 'lib/utils/shortenStringMiddle'

type Props = {
    invoices: any[]
    onClose: () => void
}

type ErrorInfo = {
    requestedCount: number
    sentCount: number
    errors: {
        invoice_id: number
        subject: string
        error: string
    }[]
}

export const SendInvoiceRemindersModal: FC<Props> = ({ invoices, onClose }) => {
    const [errorInfo, setErrorInfo] = useState<ErrorInfo | null>(null)
    const queryClient = useQueryClient()
    const queryParams = useMemo(
        () => ({
            invoice_ids: invoices.map(({ invoice_id }) => invoice_id),
        }),
        [invoices]
    )

    const { isLoading, error, data } = useQuery({
        queryKey: ['invoiceReminders', queryParams],
        queryFn: () => notificationService.getRemindersContent(queryParams),
    })

    const { mutate, isPending } = useMutation({
        mutationFn: notificationService.postInvoiceReminders,
        onError: mutationOnError(),
        onSuccess: mutationOnSuccess({
            onSuccess: ({ requestedCount, sentCount, errors }) => {
                queryClient.invalidateQueries({ queryKey: ['invoice'] })
                if (errors.length) setErrorInfo({ requestedCount, sentCount, errors })
                else {
                    toast.success(`${sentCount} Recordatorios enviados`)
                    onClose()
                }
            },
        }),
    })

    const columns = useMemo(
        () => [
            {
                header: 'Cobro',
                accessorFn: (data) => `${data.invoice_series ?? ''}${data.invoice_num}`,
                cell: (row) => (
                    <div className="d-flex align-items-center" style={{ height: 34 }}>
                        {row.getValue()}
                    </div>
                ),
                enableSorting: false,
            },
            {
                header: 'Destinatarios',
                accessorFn: (data) => {
                    const { to, cc } = data
                    const emails = [to]
                    if (cc) emails.push(...cc.split(','))
                    return emails
                },
                cell: (row) => {
                    const emails = row.getValue()
                    const firstEmail = emails[0]
                    const restEmails = emails.slice(1).join(', ')

                    const displayText = restEmails
                        ? `${firstEmail}, ${shortenStringMiddle(restEmails, 20)}`
                        : firstEmail

                    return (
                        <Tooltip trigger={<Badge>{displayText}</Badge>}>
                            {emails.join(', ')}
                        </Tooltip>
                    )
                },
                enableSorting: false,
            },
            {
                header: 'Asunto',
                accessorKey: 'subject',
                enableSorting: false,
            },
            {
                header: 'Archivos',
                accessorFn: (data) =>
                    data.attachments
                        .filter(({ filename }) => filename)
                        .map(({ filename }) => filename)
                        .join(', '),
                cell: (row) => {
                    const val = row.getValue()
                    if (!val) return <Badge variant="neutral">-</Badge>

                    return (
                        <div>
                            <Tooltip
                                trigger={
                                    <Badge variant="neutral">
                                        <Icon icon="FileEarmarkCheck" />
                                        {` ${shortenStringMiddle(val, 30)}`}
                                    </Badge>
                                }
                            >
                                {val}
                            </Tooltip>
                        </div>
                    )
                },
                enableSorting: false,
            },
        ],
        []
    )

    const errorMessage = getErrorFromQuery(error, data)

    const renderBody = () => {
        if (isLoading) return <Spinner />
        if (errorMessage || !data)
            return <ErrorMessage>Ha ocurrido un error: {errorMessage}</ErrorMessage>

        if (errorInfo) {
            const { requestedCount, sentCount, errors } = errorInfo
            return (
                <div>
                    <p>
                        Se enviaron {sentCount} de los {requestedCount} correos
                        solicitados.
                    </p>
                    <Banner variant="warning">
                        <ul>
                            {errors.map(({ invoice_id, subject, error }) => (
                                <li key={invoice_id}>
                                    {subject}: {error}
                                </li>
                            ))}
                        </ul>
                    </Banner>
                    <Button variant="secondary" onClick={onClose}>
                        Cerrar
                    </Button>
                </div>
            )
        }

        const { emailReminders } = data
        return (
            <>
                <p>Se enviarán {emailReminders.length} recordatorios por email:</p>
                <Table columns={columns} data={emailReminders} />
                <Button
                    className="inline-md"
                    isLoading={isPending ? 'Enviando...' : undefined}
                    onClick={() => mutate({ emailReminders })}
                >
                    Enviar
                </Button>
                <Button variant="secondaryText" onClick={onClose}>
                    Cancelar
                </Button>
            </>
        )
    }

    return (
        <Modal title="Enviar Recordatorios" onClose={onClose}>
            {renderBody()}
        </Modal>
    )
}
