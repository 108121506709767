import {
    useReactTable,
    getSortedRowModel,
    getPaginationRowModel,
    getCoreRowModel,
    flexRender,
    getExpandedRowModel,
    ExpandedState,
    ColumnDef,
} from '@tanstack/react-table'
import styles from './style.module.scss'
import cx from 'classnames'
import { Button, ButtonVariant, Tooltip, Pagination } from 'ds/components'
import { Fragment, ReactElement, useEffect, useMemo, useRef, useState } from 'react'
import { useRouter } from 'next/router'
import { downloadToCsv } from 'lib/utils/downloadToCsv'
import { getSortByFromString, getSortByString } from 'lib/utils/getSortByString'

export type TableColumnDef = ColumnDef<any, any> & {
    align?: 'right' | 'center'
    width?: number
    strong?: boolean
    help?: string
    enableSorting?: boolean
}

export type ManualPagination = {
    offset: number
    limit: number
    result_count: number
    total_count: number
    onPrevPage: () => void
    onNextPage: () => void
}

export type BatchConfig = {
    label: string
    action: (rows: any[], clearRows: () => void) => Promise<void> | void

    alwaysShow?: boolean
    disabled?: boolean
    loadingText?: string
    clearSelection?: boolean
    tooltip?: string
    variant?: ButtonVariant
}

type Props = {
    exportToExcelFilename?: string // DEPRECATED
    columns: TableColumnDef[]
    data: any[]
    clientSidePagination?: boolean
    manualPagination?: ManualPagination
    isLoading?: boolean
    initState?: { expanded: boolean }

    renderSubComponent?: (props: { row: any }) => JSX.Element
    onDownload?: () => void
    onSort?: (sortBy: string | null) => void
    getRowLink?: (row: any) => string | null | undefined
    getSubRows?: (row: any) => any[]
    isRowSelectable?: (row: any) => boolean

    // New Nacho Stuff
    batchConfigs?: BatchConfig[]
    openRowId?: number
    setClickedRowId?: (id: number | null) => void
    sortBy?: string | null
    disableSorting?: boolean
    maxHeight?: number
    noDataComponent?: ReactElement | string
    preserveExpandedState?: boolean
    onSelectionChange?: (selectedRows: any[]) => void
}

export const Table = ({
    exportToExcelFilename,
    onDownload,
    onSort,
    columns,
    data,
    getRowLink,
    renderSubComponent,
    clientSidePagination,
    manualPagination,
    getSubRows,
    isLoading,
    initState,
    isRowSelectable,
    // New stuff for more control by Nacho
    batchConfigs,
    openRowId,
    setClickedRowId,
    sortBy,
    disableSorting,
    maxHeight,
    noDataComponent,
    preserveExpandedState,
    onSelectionChange,
}: Props) => {
    const router = useRouter()

    const [expanded, setExpanded] = useState<ExpandedState>(initState?.expanded || {})
    const [rowSelection, setRowSelection] = useState({})
    const [sorting, setSorting] = useState(getSortByFromString(sortBy))
    const [pagination, setPagination] = useState({ pageIndex: 0, pageSize: 50 })

    useEffect(() => {
        if (!preserveExpandedState)
            setExpanded((prevExpanded) => (prevExpanded === true ? true : {}))
        setRowSelection({})
    }, [data, preserveExpandedState])

    const table = useReactTable({
        data,
        columns,
        manualPagination: !!manualPagination,
        manualSorting: onSort ? true : undefined,
        state: {
            expanded,
            sorting,
            rowSelection,
            pagination,
        },
        getCoreRowModel: getCoreRowModel(),

        onRowSelectionChange: setRowSelection,

        enableRowSelection: isRowSelectable || true,

        onSortingChange: (sortingUpdater) => {
            const newSortVal =
                typeof sortingUpdater === 'function'
                    ? sortingUpdater(sorting)
                    : sortingUpdater
            if (onSort) {
                const [sortingObject] = newSortVal
                const key = sortingObject?.id
                const dir = sortingObject?.desc ? 'desc' : 'asc'

                onSort(getSortByString({ key, dir }))
            }
            setSorting(newSortVal)
        },
        getSortedRowModel: getSortedRowModel(),

        onPaginationChange: setPagination,
        getPaginationRowModel: clientSidePagination ? getPaginationRowModel() : undefined,

        getExpandedRowModel: getExpandedRowModel(),
        getRowCanExpand: renderSubComponent ? () => true : undefined,
        onExpandedChange: setExpanded,
        getSubRows,
        enableSorting: !disableSorting,
    })

    const tableRef = useRef(null)
    const countRowsSelected = useMemo(
        () => Object.values(rowSelection).filter(Boolean).length,
        [rowSelection]
    )

    useEffect(() => {
        if (!onSelectionChange) return
        const selectedRows = table
            .getSelectedRowModel()
            .flatRows.map((row) => row.original)
        onSelectionChange(selectedRows)
    }, [rowSelection, table, onSelectionChange])

    const batchActionsShown = useMemo(() => {
        if (!batchConfigs?.length) return false
        if (!countRowsSelected) return !!batchConfigs.find((config) => config.alwaysShow)

        return true
    }, [batchConfigs, countRowsSelected])

    const hasRowsForPagination = manualPagination
        ? !!manualPagination.result_count
        : clientSidePagination
        ? !!data.length
        : false

    const paginationComponent = manualPagination ? (
        <Pagination
            isLoading={isLoading}
            offset={manualPagination.offset}
            result_count={manualPagination.result_count}
            total_count={manualPagination.total_count}
            onPrevPage={() => manualPagination.onPrevPage()}
            onNextPage={() => manualPagination.onNextPage()}
        />
    ) : clientSidePagination ? (
        <Pagination
            isLoading={isLoading}
            offset={table.getState().pagination.pageIndex * pagination.pageSize}
            result_count={table.getRowModel().rows.length}
            total_count={data.length}
            onPrevPage={() => table.previousPage()}
            onNextPage={() => table.nextPage()}
        />
    ) : null

    const renderBatchActions = () => {
        if (!batchConfigs?.length) return null
        return (
            <>
                {countRowsSelected ? (
                    <span className="inline-md">
                        {countRowsSelected === 1
                            ? '1 seleccionado'
                            : `${countRowsSelected} seleccionados`}
                    </span>
                ) : null}
                {batchConfigs.map((batchConfig) => {
                    if (!batchConfig.alwaysShow && !countRowsSelected) return null
                    return (
                        <Button
                            className="inline-sm"
                            disabled={batchConfig.disabled || !countRowsSelected}
                            isLoading={batchConfig.loadingText}
                            key={batchConfig.label}
                            onClick={() => {
                                const selectedRows = table
                                    .getSelectedRowModel()
                                    .flatRows.map((row) => row.original)
                                batchConfig.action(selectedRows, () =>
                                    setRowSelection({})
                                )
                                if (batchConfig.clearSelection) setRowSelection({})
                            }}
                            size="sm"
                            tooltipContent={batchConfig.tooltip}
                            variant={batchConfig.variant ?? 'primary'}
                        >
                            {batchConfig.label}
                        </Button>
                    )
                })}
            </>
        )
    }

    return (
        <>
            <div className="d-flex align-items-center stacked-sm">
                <div className="flex-fill">{renderBatchActions()}</div>
                {exportToExcelFilename ? (
                    // DEPRECATED --> Should pass downloadToCsv() in as onDownload.
                    <Button
                        className="inline-md"
                        variant="secondaryText"
                        size="sm"
                        icon="download"
                        onClick={() => {
                            downloadToCsv({
                                data,
                                getSubRows,
                                filename: exportToExcelFilename,
                            })
                        }}
                    />
                ) : null}
                {onDownload ? (
                    <Button
                        isLoading={isLoading}
                        disabled={!data.length}
                        className="inline-md"
                        variant="secondaryText"
                        size="sm"
                        icon="download"
                        onClick={onDownload}
                    />
                ) : null}
                <span className={`${batchActionsShown ? 'd-none' : ''} d-md-block`}>
                    {paginationComponent}
                </span>
            </div>

            <div className="overflow-wrapper stacked-md" style={{ maxHeight }}>
                <table
                    className={cx(styles.table, isLoading && styles.loading)}
                    ref={tableRef}
                >
                    <thead>
                        {table.getHeaderGroups().map((headerGroup) => (
                            <tr key={headerGroup.id}>
                                {headerGroup.headers.map((header) => {
                                    const { id, column } = header

                                    const { width, align, help } =
                                        column.columnDef as TableColumnDef
                                    const canSort = column.getCanSort() ?? true
                                    const sortDir = column.getIsSorted()
                                    const headerDiv = help ? (
                                        <Tooltip
                                            disableClick
                                            placement="top-end"
                                            trigger={column.columnDef.header as string}
                                        >
                                            <span className={styles.help}>{help}</span>
                                        </Tooltip>
                                    ) : (
                                        column.columnDef.header
                                    )
                                    return (
                                        <th
                                            key={id}
                                            style={{ width }}
                                            className={styles.th}
                                            onClick={column.getToggleSortingHandler()}
                                        >
                                            <div
                                                className={cx({
                                                    right: align === 'right',
                                                    center: align === 'center',
                                                    pointer: canSort,
                                                })}
                                            >
                                                {flexRender(
                                                    headerDiv,
                                                    header.getContext()
                                                )}
                                                {sortDir ? (
                                                    <span>
                                                        {sortDir === 'asc' ? ' ↑' : ' ↓'}
                                                    </span>
                                                ) : null}
                                            </div>
                                        </th>
                                    )
                                })}
                            </tr>
                        ))}
                    </thead>
                    <tbody>
                        {table.getRowModel().rows.map((row) => {
                            const subComponent =
                                renderSubComponent &&
                                ((openRowId && row.original.id === openRowId) ||
                                    row.getIsExpanded())
                                    ? renderSubComponent({ row })
                                    : null
                            const visibleCells = row.getVisibleCells()
                            const showPointer =
                                row.original.isClickable ??
                                (getRowLink || renderSubComponent)

                            return (
                                <Fragment key={row.id}>
                                    <tr
                                        onClick={(e) => {
                                            if (setClickedRowId) {
                                                setClickedRowId(
                                                    openRowId === row.original.id
                                                        ? null
                                                        : row.original.id
                                                )
                                            } else if (renderSubComponent) {
                                                row.getToggleExpandedHandler()()
                                            }
                                            if (!getRowLink) return false

                                            const url = getRowLink(row.original)
                                            if (!url) return
                                            const openInNewTab = e.ctrlKey || e.metaKey
                                            if (openInNewTab) {
                                                window.open(url, '_blank')
                                            } else {
                                                router.push(url)
                                            }
                                        }}
                                        className={cx(styles.tr, 'ease', {
                                            pointer: showPointer,
                                        })}
                                    >
                                        {visibleCells.map((cell) => {
                                            const columnDef = cell.column.columnDef

                                            return (
                                                <td
                                                    key={cell.id}
                                                    className={cx(
                                                        styles.td,
                                                        getClassNamesForCell(columnDef)
                                                    )}
                                                >
                                                    {flexRender(
                                                        columnDef.cell,
                                                        cell.getContext()
                                                    )}
                                                </td>
                                            )
                                        })}
                                    </tr>
                                    {subComponent ? (
                                        <tr>
                                            <td colSpan={visibleCells.length}>
                                                {subComponent}
                                            </td>
                                        </tr>
                                    ) : null}
                                </Fragment>
                            )
                        })}
                    </tbody>

                    <tfoot>
                        {table.getFooterGroups().map((footerGroup) => (
                            <tr key={footerGroup.id}>
                                {footerGroup.headers.map((header) => {
                                    const columnDef = header.column
                                        .columnDef as TableColumnDef
                                    return (
                                        <th
                                            key={header.id}
                                            className={cx(
                                                styles.footerCell,
                                                getClassNamesForCell(columnDef)
                                            )}
                                        >
                                            {flexRender(
                                                columnDef.footer,
                                                header.getContext()
                                            )}
                                        </th>
                                    )
                                })}
                            </tr>
                        ))}
                    </tfoot>
                </table>
            </div>

            {!data.length ? (
                <div className="stacked-sm">
                    <div className="center">
                        {noDataComponent || 'No se encontraron datos.'}
                    </div>
                </div>
            ) : null}

            <div className="stacked-sm d-flex justify-content-end">
                {hasRowsForPagination ? paginationComponent : null}
            </div>
        </>
    )
}

const getClassNamesForCell = ({ align, strong }: TableColumnDef) => {
    return cx({
        right: align === 'right',
        center: align === 'center',
        strong,
    })
}
