import { Tag } from 'lib/models/tag'
import { makeApiRequest } from 'lib/utils/apiUtils/makeApiRequest'

const url = `/tags`

const baseKey = 'tags'

export const tagQueryKeys = {
    list: [baseKey],
    invalidateAll: [baseKey],
}

export const tagService = {
    getTags: async (queryParams?: { usage: boolean }) =>
        makeApiRequest<GetTagData>({ url, queryParams }),
    postTag: async (body: { name: string }) =>
        makeApiRequest({ url, method: 'POST', body }),
    archiveTag: async (tagId: number) =>
        makeApiRequest({ url: `${url}/${tagId}`, method: 'DELETE' }),
    bulkTag: async (body: BulkTagBody) =>
        makeApiRequest({
            url: `${url}/bulk-tag`,
            method: 'POST',
            body,
        }),
}

type GetTagData = {
    tags: Tag[]
    tag_usage?: Record<
        number,
        | { invoice_count: number; subscription_count: number; customer_count: number }
        | undefined
    >
}

type BulkTagBody = {
    tag_ids: number[]
} & ({ invoice_ids: number[] } | { subscription_ids: number[] })
