import { useContext, useMemo } from 'react'

import { Select, Option, Checkbox, NumberInput } from 'ds/components'
import { GetExchangeRateHelp } from 'lib/common/GetExchangeRateHelp'

import { useForm } from 'lib/utils/useForm'
import { getError } from 'lib/utils/getError'
import { formaDePago } from 'lib/constants/formaDePago'
import { BulkCreateCfdiRow } from './BulkCreateCfdiRow'
import { usoCfdi } from 'lib/constants/usoCfdi'
import { SessionContext } from 'lib/hoc/withSession'
import { InvoiceV2 } from 'lib/models/invoice'

type FormData = {
    payment_method: string
    use: string
    payment_form: string
    exchange: string
    send_email: boolean
}

type Props = {
    invoices: InvoiceV2[]
}

export const BulkCreateCfdis = ({ invoices }: Props) => {
    const account = useContext(SessionContext).metadata.account

    const hasUsd = useMemo(
        () => invoices.some((invoice) => invoice.currency === 'USD'),
        [invoices]
    )

    const initialValues: FormData = useMemo(
        () => ({
            payment_method: account.cfdi_payment_method || 'PUE',
            use: account.cfdi_use || 'G03',
            payment_form: account.cfdi_payment_form || '03',
            exchange: '',
            send_email: true,
            show_preview: false,
        }),
        [account]
    )

    const initialFormContext = useMemo(() => getInitialFormContext(hasUsd), [hasUsd])

    const {
        formData,
        formContext,
        handleBlur,
        handleChange,
        setFormError,
        getFormError,
    } = useForm({ initialValues, initialFormContext })

    return (
        <div>
            <div className="row stacked-md gy-2">
                <Select
                    label="Uso CFDI"
                    className="col-lg"
                    required={formContext.use.required}
                    value={formData.use}
                    error={formContext.use.isDirty && formContext.use.error}
                    onChange={(value) => handleChange('use', value)}
                >
                    {usoCfdi.map((item) => {
                        return (
                            <Option value={item.code} key={item.code}>
                                {item.code} - {item.description}
                            </Option>
                        )
                    })}
                </Select>
                {hasUsd ? (
                    <NumberInput
                        className="col-lg"
                        label="Intercambio USD/MXN"
                        variant="currency"
                        decimalScale={4}
                        required={formContext.exchange.required}
                        value={formData.exchange}
                        error={formContext.exchange.isDirty && formContext.exchange.error}
                        onChange={(value) => handleChange('exchange', value)}
                        onBlur={() => handleBlur('exchange')}
                        help={
                            <GetExchangeRateHelp
                                onChange={(val) => handleChange('exchange', val)}
                            />
                        }
                    />
                ) : null}
                <Select
                    label="Método de pago"
                    className="col-lg"
                    required={formContext.payment_method.required}
                    value={formData.payment_method}
                    error={
                        formContext.payment_method.isDirty &&
                        formContext.payment_method.error
                    }
                    onChange={(value) => {
                        handleChange('payment_method', value)
                        if (value === 'PPD') {
                            handleChange('payment_form', '99')
                        }
                    }}
                >
                    <Option value="PUE">Pago en Una sola Exhibición (PUE)</Option>
                    <Option value="PPD">Pago en Parcialidades o Diferido (PPD)</Option>
                </Select>
                <Select
                    className="col-lg"
                    label="Forma de pago"
                    required={formContext.payment_form.required}
                    value={formData.payment_form}
                    error={
                        formContext.payment_form.isDirty && formContext.payment_form.error
                    }
                    onChange={(value) => handleChange('payment_form', value)}
                >
                    {formaDePago.map((item) => {
                        return (
                            <Option value={item.code} key={item.code}>
                                {item.code} - {item.description}
                            </Option>
                        )
                    })}
                </Select>
            </div>
            <Checkbox
                className="stacked-lg"
                checked={formData.send_email}
                onChange={(value) => handleChange('send_email', value)}
            >
                Enviar factura por email
            </Checkbox>
            {invoices.map((invoice) => (
                <BulkCreateCfdiRow
                    key={invoice.invoice_id}
                    invoice={invoice}
                    formData={formData}
                    getFormError={getFormError}
                    setFormError={setFormError}
                />
            ))}
        </div>
    )
}

const getInitialFormContext = (hasUsd) => ({
    payment_method: {
        required: true,
        getError: (v) => getError.required(v),
    },
    use: { required: true, getError: (v) => getError.required(v) },
    payment_form: { required: true, getError: (v) => getError.required(v) },
    exchange: {
        required: true,
        getError: (v) => hasUsd && getError.required(v),
    },
})
