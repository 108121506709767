import { makeApiRequest } from 'lib/utils/apiUtils/makeApiRequest'
import { InvoiceReminderConfig } from 'lib/models/invoiceReminderConfig'
import { Frequency } from 'lib/models/customerReminderConfig'

const url = '/invoice-reminder-configs'

const baseKey = 'invoice_reminder_configs'

export const invoiceReminderConfigQueryKeys = {
    invalidateAll: [baseKey],
    getInvoiceReminderConfig: [baseKey],
    getCustomerInvoiceReminderConfig: (customerId: number) => [baseKey, { customerId }],
}

export const invoiceReminderConfigService = {
    getInvoiceReminderConfig: async (customerId?: number) =>
        makeApiRequest<GetInvoiceReminderConfigData>({
            url: customerId ? `${url}/customer/${customerId}` : url,
        }),
    // Handles both insert or update
    // Handles both invoice and customer reminder config
    putInvoiceReminderConfig: async (body: PutInvoiceReminderConfig) =>
        makeApiRequest({ url, method: 'PUT', body }),
    putCustomerInvoiceReminderConfig: async (body: PutCustomerInvoiceReminderConfig) =>
        makeApiRequest({ url: `${url}/customer`, method: 'PUT', body }),
}

type GetInvoiceReminderConfigData = {
    invoice_reminder_config: InvoiceReminderConfig
}

type PutCustomerInvoiceReminderConfig = {
    customer_id: number
    disable_reminders: boolean
}

type PutInvoiceReminderConfig = {
    customer_reminder_config_payload: CustomerReminderConfigPayload
    invoice_reminder_config_payload: InvoiceReminderConfigPayload
}

export type CustomerReminderConfigPayload = {
    customer_reminder_config_id: number | null
    is_enabled: boolean | 0 | 1
    attachment: string
    frequency: Frequency
    customer_receivables_statement_cfdi_only: boolean | 0 | 1
}

export type InvoiceReminderConfigPayload = {
    invoice_reminder_config_id: number | null
    email_sequence: string | null
    attachments: string | null
}
