import { makeApiRequest } from 'lib/utils/apiUtils/makeApiRequest'

const url = '/notifications'

export const notificationService = {
    postNotification: async (body) => makeApiRequest({ url, method: 'POST', body }),
    postInvoiceReminders: async (body) =>
        makeApiRequest({ url: `${url}/reminders`, method: 'POST', body }),
    // Use POST because getNotificationContent takes emailSubject and emailBody,
    // Too complex to send as query params
    getNotificationContent: async (queryParams) =>
        makeApiRequest({ url: `${url}/content`, queryParams }),
    getRemindersContent: async (queryParams) =>
        makeApiRequest({ url: `${url}/reminders`, queryParams }),
    getNotificationPreview: async (body) =>
        makeApiRequest({ url: `${url}/preview`, method: 'POST', body }),
    superadminGetAll: async () => makeApiRequest({ url: '/superadmin/notification' }),
}
