import { FC, useState } from 'react'
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'

import { Button, Checkbox, ErrorMessage, Modal, Spinner } from 'ds/components'

import { invoiceService } from 'lib/services/invoiceService'
import { mutationOnError, mutationOnSuccess } from 'lib/utils/mutationUtils'
import { getErrorFromQuery } from 'lib/utils/getErrorFromQuery'

type Props = {
    invoice: any
    closeModal: () => void
}

export const DeleteInvoiceModal: FC<Props> = ({ invoice, closeModal }) => {
    const queryClient = useQueryClient()
    const [bypass, setBypass] = useState(false)

    const { data, isLoading, error } = useQuery({
        queryKey: ['invoice-deletion-eligibility', invoice.invoice_id],
        queryFn: () => invoiceService.getInvoiceDeletionEligibility(invoice.invoice_id),
    })

    const errorMessage = getErrorFromQuery(error, data)

    const { mutate, isPending } = useMutation({
        mutationFn: () =>
            invoiceService.deleteInvoice({
                invoice_id: invoice.invoice_id,
                bypass_cfdi_check: bypass,
            }),
        onError: mutationOnError(),
        onSuccess: mutationOnSuccess({
            toastSuccess: 'Cobro borrado.',
            onSuccess: () => {
                queryClient.invalidateQueries({ queryKey: ['invoice'] })
                queryClient.invalidateQueries({ queryKey: ['subscription'] })
                closeModal()
            },
        }),
    })

    const renderBypassCheckbox = () => {
        return (
            <div className="mb-3">
                <p>
                    Este cobro tiene un CFDI vigente y como fue creado por fuera de Savio
                    no podemos cancelarlo. ¿Gustas marcarlo manualmente como cancelado
                    para poder borrar el cobro?
                </p>
                <Checkbox checked={bypass} onChange={setBypass}>
                    Marcar factura como cancelada
                </Checkbox>
            </div>
        )
    }

    const renderBody = () => {
        if (isLoading) return <Spinner />
        if (errorMessage || !data) {
            return <ErrorMessage>Algo salió mal: {errorMessage}</ErrorMessage>
        }

        return (
            <>
                {data.can_delete ? (
                    data.needs_bypass ? (
                        renderBypassCheckbox()
                    ) : (
                        <p>¿Seguro que quieres borrar este cobro?</p>
                    )
                ) : (
                    <ErrorMessage className="mb-3">
                        Este cobro no puede ser borrado porque tiene una factura vigente
                        asociada. Se tiene que cancelar la factura primero.
                    </ErrorMessage>
                )}
                <Button
                    disabled={!data.can_delete || (data.needs_bypass && !bypass)}
                    isLoading={isPending ? 'Borrando...' : undefined}
                    variant="danger"
                    className="inline-md"
                    onClick={() => mutate({})}
                >
                    Borrar cobro
                </Button>
                <Button variant="secondaryText" onClick={closeModal}>
                    Cancelar
                </Button>
            </>
        )
    }

    return (
        <Modal title="Borrar Cobro" size="sm" onClose={closeModal}>
            {renderBody()}
        </Modal>
    )
}
