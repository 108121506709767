import { FC } from 'react'

import { Option, Select, SelectButtons, OptionButton } from 'ds/components'

import { FormContext } from 'lib/hooks/useForm'

type Props = {
    className?: string
    currency: string | null
    error?: string
    required?: boolean
    showAllOption?: boolean
    disabled?: boolean
    formContext?: FormContext
    onChange: (currency: string) => void
}

export const CurrencySelect: FC<Props> = ({
    className,
    required,
    showAllOption,
    currency,
    error,
    onChange,
    disabled,
    formContext,
}) => {
    return (
        <Select
            className={className}
            disabled={disabled}
            error={error}
            formContext={formContext}
            label="Moneda"
            onChange={onChange}
            required={required}
            value={currency || ''}
        >
            <Option value="MXN">MXN</Option>
            <Option value="USD">USD</Option>
            {showAllOption ? <Option value="all">Todas</Option> : null}
        </Select>
    )
}

type CurrencyOption = 'MXN' | 'USD' | null

type SelectButtonsProps = {
    className?: string
    label?: string
    currency: CurrencyOption
    onChange: (currency: CurrencyOption) => void
    showAllOption?: boolean
}

export const CurrencySelectButtons: FC<SelectButtonsProps> = ({
    className,
    label,
    currency,
    onChange,
    showAllOption,
}) => {
    return (
        <SelectButtons
            className={className}
            value={currency || ''}
            label={label}
            onChange={(val) => onChange((val || null) as CurrencyOption)}
        >
            {showAllOption ? <OptionButton value="">Todo</OptionButton> : null}
            <OptionButton value="MXN">MXN</OptionButton>
            <OptionButton value="USD">USD</OptionButton>
        </SelectButtons>
    )
}
