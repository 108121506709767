import { ReactElement } from 'react'
import cx from 'classnames'

import styles from './style.module.scss'
import { Tooltip } from '../Tooltip'

type Props = {
    value: boolean
    label?: ReactElement | string
    loading?: boolean
    className?: string
    disabled?: boolean
    size?: 'sm' | 'md' | 'lg'
    labelTooltip?: ReactElement | string

    onChange: (value: boolean) => void
    onClick?: () => void
}

export const Switch = ({
    label,
    labelTooltip,
    value,
    loading,
    onChange,
    onClick,
    disabled,
    className,
    size,
}: Props) => {
    return (
        <div className={cx('d-flex align-items-center gap-2', className)}>
            <label
                className={cx(styles.switch, {
                    [styles.disabled]: disabled || loading,
                    [styles.small]: size === 'sm',
                    [styles.medium]: size === 'md',
                })}
            >
                <input
                    className={styles.input}
                    type="checkbox"
                    disabled={disabled || loading}
                    checked={value}
                    onChange={() => {}}
                    onClick={(e) => {
                        e.preventDefault()
                        if (onClick) onClick()
                        onChange(!value)
                    }}
                />
                <span className={cx(styles.slider, { [styles.checked]: value })} />
            </label>
            {label ? (
                labelTooltip ? (
                    <Tooltip trigger={label}>{labelTooltip}</Tooltip>
                ) : (
                    <span className={styles.label}>{label}</span>
                )
            ) : null}
        </div>
    )
}
