import { FC, useState } from 'react'
import { toast } from 'react-toastify'
import { useMutation, useQueryClient } from '@tanstack/react-query'

import { Banner, Button, Modal, NumberInput } from 'ds/components'

import { mutationOnError, mutationOnSuccess } from 'lib/utils/mutationUtils'
import { invoiceService } from 'lib/services/invoiceService'

type Props = {
    invoices: any[]
    onClose: () => void
}

type ConflictInfo = {
    requestedCount: number
    deletedCount: number
    conflicts: {
        error: string
        metadata?: {
            invoice_id: number
            series: string | null
            invoice_num: string
        }
    }[]
}

export const DeleteInvoicesModal: FC<Props> = ({ invoices, onClose }) => {
    const queryClient = useQueryClient()

    const [countInvoicesInput, setCountInvoicesInput] = useState<number | null>(null)
    const [conflictInfo, setConflictInfo] = useState<ConflictInfo | null>(null)
    const countInvoices = invoices.length

    const { mutate, isPending } = useMutation({
        mutationFn: invoiceService.deleteInvoices,
        onError: mutationOnError(),
        onSuccess: mutationOnSuccess({
            onSuccess: ({ requestedCount, deletedCount, conflicts }) => {
                queryClient.invalidateQueries({ queryKey: ['invoice'] })
                queryClient.invalidateQueries({ queryKey: ['subscription'] })
                if (conflicts.length) {
                    setConflictInfo({ requestedCount, deletedCount, conflicts })
                } else {
                    toast.success(`${deletedCount} cobros borrados.`)
                    onClose()
                }
            },
        }),
    })

    const renderBody = () => {
        if (conflictInfo) {
            const { requestedCount, deletedCount, conflicts } = conflictInfo
            return (
                <div>
                    <p>
                        Se borraron {deletedCount} de los {requestedCount} cobros
                        solicitados.
                    </p>
                    <Banner variant="warning">
                        <ul>
                            {conflicts.map(({ metadata, error }, idx) => {
                                if (!metadata) return <li key={idx}>Error: {error}</li>

                                const { series, invoice_num } = metadata
                                return (
                                    <li key={idx}>
                                        Cobro {series ?? ''}
                                        {invoice_num}: {error}
                                    </li>
                                )
                            })}
                        </ul>
                    </Banner>
                    <Button variant="secondary" onClick={onClose}>
                        Cerrar
                    </Button>
                </div>
            )
        }
        return (
            <>
                <div className="row gy-2">
                    <p>¿Seguro que quieres borrar estos cobros?</p>
                    <NumberInput
                        className="mb-4"
                        label="Teclea la cantidad de cobros para confirmar"
                        value={countInvoicesInput}
                        onChange={(num) => setCountInvoicesInput(num)}
                    />
                </div>

                <Button
                    disabled={countInvoicesInput !== countInvoices}
                    isLoading={isPending ? 'Borrando...' : undefined}
                    className="inline-md"
                    variant="danger"
                    onClick={() => {
                        mutate({
                            invoice_ids: invoices.map(({ invoice_id }) => invoice_id),
                        })
                    }}
                >
                    Confirmar y borrar
                </Button>
                <Button variant="secondaryText" onClick={onClose}>
                    Cancelar
                </Button>
            </>
        )
    }

    return (
        <Modal
            size={conflictInfo ? 'lg' : 'sm'}
            title={`Borrar ${countInvoices} Cobros`}
            onClose={onClose}
        >
            {renderBody()}
        </Modal>
    )
}
