import { FC, useState } from 'react'
import { useLocalStorage } from 'usehooks-ts'

import { ReceivablesAgingKpis } from './ReceivablesAgingKpis'
import { DashboardRevenueChart } from './DashboardRevenueChart'
import { ReceivablesAgingChart } from '../ReceivablesAgingChart'
import { PeriodicKpis } from './PeriodicKpis'
import { OrganizationSelect } from 'lib/common/OrganizationSelect'
import { CurrencySelectButtons } from 'lib/common/CurrencySelect'

export const AccountsReceivablesDashboard: FC = () => {
    const [currency, setCurrency] = useLocalStorage<'MXN' | 'USD'>(
        'dashboard_currency',
        'MXN'
    )

    const [organizationId, setOrganizationId] = useState<number | null>(null)

    return (
        <>
            <div className="mb-3 d-flex gap-3">
                <CurrencySelectButtons
                    currency={currency}
                    onChange={(val) => setCurrency(val || 'MXN')}
                />
                <OrganizationSelect
                    organizationId={organizationId}
                    onChange={setOrganizationId}
                />
            </div>

            <ReceivablesAgingKpis currency={currency} organization_id={organizationId} />

            <div className="row">
                <div className="col-xl-6">
                    <DashboardRevenueChart
                        currency={currency}
                        organization_id={organizationId}
                    />
                </div>
                <div className="col-xl-6">
                    <ReceivablesAgingChart
                        currency={currency}
                        organization_id={organizationId}
                    />
                </div>
            </div>

            <PeriodicKpis currency={currency} organization_id={organizationId} />
        </>
    )
}
