import { FC, useContext, useMemo, useState } from 'react'
import { useMutation, useQueryClient } from '@tanstack/react-query'

import { SelectMultipleInput } from 'ds/components/SelectMultipleInput'
import { Button } from 'ds/components/Button'
import { Modal } from 'ds/components/Modal'
import { Input } from 'ds/components/Input'
import { toast } from 'react-toastify'
import { Badge } from 'ds/components/Badge'
import { Tag } from './Tag'

import { SessionContext } from 'lib/hoc/withSession'
import { tagQueryKeys, tagService } from 'lib/services/tagService'
import { Tag as TagModel } from 'lib/models/tag'

type Props = {
    selectedTagIds: number[]
    onChange: (tagIds: number[]) => void

    allowCreate?: boolean
    placeholder?: string
    label?: string
    className?: string
}

export const TagSelect: FC<Props> = ({
    selectedTagIds,
    onChange,
    allowCreate,
    placeholder,
    label,
    className,
}) => {
    const { metadata } = useContext(SessionContext)
    const queryClient = useQueryClient()

    const [newTagName, setNewTagName] = useState<string | undefined>(undefined)

    const { isPending, mutate } = useMutation({
        mutationFn: (name: string) => tagService.postTag({ name }),
    })

    const tags = useMemo(() => Object.values(metadata.tagsById), [metadata.tagsById])

    const selectedTags = useMemo(
        () =>
            selectedTagIds?.map((tagId) => metadata.tagsById[tagId]).filter(Boolean) ||
            [],

        [selectedTagIds, metadata.tagsById]
    )

    if (!tags.length && !allowCreate) return null

    return (
        <>
            <SelectMultipleInput
                className={className}
                label={label}
                maxWidth={allowCreate ? undefined : 80}
                items={tags}
                placeholder={placeholder || 'Selecciona tags'}
                selectedItems={selectedTags}
                setSelectedItems={(newTags: TagModel[]) =>
                    onChange(newTags.map((tag) => tag.tag_id))
                }
                getItemId={(tag) => tag.tag_id}
                itemToString={(tag) => tag.name}
                renderItem={(tag) => <Tag tag={tag} />}
                renderSelectedItem={(tag, onRemove) => (
                    <Badge bgColor={tag.color}>
                        {tag.name}
                        <span className="px-1 pointer" onClick={onRemove}>
                            &#10005;
                        </span>
                    </Badge>
                )}
                suggestedAction={
                    allowCreate ? (
                        <Button
                            fullWidth
                            size="sm"
                            variant="secondaryText"
                            type="button"
                            onClick={() => setNewTagName('')}
                        >
                            Crear tag
                        </Button>
                    ) : null
                }
            />
            {newTagName !== undefined && allowCreate ? (
                <Modal
                    size="sm"
                    onClose={() => setNewTagName(undefined)}
                    title="Crear tag"
                >
                    <Input
                        className="mb-4"
                        label="Nombre"
                        onChange={setNewTagName}
                        value={newTagName}
                    />
                    <Button
                        disabled={isPending || newTagName.trim() === ''}
                        onClick={() => {
                            if (!newTagName.trim()) return
                            mutate(newTagName.trim(), {
                                onError: () => {
                                    toast.error('Algo salió mal')
                                },
                                onSuccess: (data) => {
                                    if (data.error) {
                                        toast.error(data.error)
                                        return
                                    } else if (data.tag_id) {
                                        onChange([...selectedTagIds, data.tag_id])
                                    }

                                    queryClient.invalidateQueries({
                                        queryKey: tagQueryKeys.invalidateAll,
                                    })
                                    toast.success('Tag creado')
                                    setNewTagName(undefined)
                                },
                            })
                        }}
                        type="button"
                    >
                        Crear
                    </Button>
                </Modal>
            ) : null}
        </>
    )
}
