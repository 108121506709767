import { useCallback, useState } from 'react'
import Link from 'next/link'
import { Subscription } from 'lib/models/subscription'

import { Modal, DropdownButton } from 'ds/components'
import { RecordPaymentFormLoader } from '../RecordPaymentFormLoader'
import { DeleteInvoiceModal } from './DeleteInvoiceModal'
import { SendNotificationModal } from '../SendNotificationModal'
import { RetryFailedAutoChargePaymentModal } from '../RetryFailedAutoChargePaymentModal'
import { toast } from 'react-toastify'

type Props = {
    invoice: any
    canEdit: boolean
    canDelete: boolean
    canCreatePayment: boolean
    subscription?: Subscription
}
export const InvoiceActionsButton = ({
    invoice,
    subscription,
    canEdit,
    canDelete,
    canCreatePayment,
}: Props) => {
    const [showModal, setShowModal] = useState('')

    const closeModal = useCallback(() => setShowModal(''), [])

    // Option to retry failed payment is available if:
    // Latest payment failed
    // Invoice belongs to a subscription that has auto-charged enabled
    // Option is available in failed invoices in subscription page
    const canRetryFailedAutoChargePayment =
        subscription &&
        invoice.ecart_latest_payment_attempt_status === 'declined' &&
        !invoice.settled_date &&
        subscription.ecart_auto_charge_config_id &&
        subscription.ecart_payment_method_id

    return (
        <div>
            <DropdownButton size="sm" variant="secondaryText" icon="three-dots">
                {!invoice.settled_date ? (
                    <a onClick={() => setShowModal('send')}>Enviar recordatorio</a>
                ) : null}
                {canRetryFailedAutoChargePayment ? (
                    <a onClick={() => setShowModal('retry-payment')}>Reintentar pago</a>
                ) : null}
                {invoice.payment_link ? (
                    <a onClick={() => handleCopyPaymentLink(invoice.payment_link)}>
                        Copiar liga de pago
                    </a>
                ) : null}
                {canCreatePayment && !invoice.settled_date ? (
                    <a onClick={() => setShowModal('payment')}>Registrar pago</a>
                ) : null}
                <Link href={`/invoices/${invoice.invoice_id}/sales-order`}>
                    {invoice.settled_date ? 'Recibo' : 'Nota de venta'}
                </Link>
                <Link href={`/invoices/${invoice.invoice_id}/cfdi`}>CFDIs</Link>
                {canEdit && !invoice.settled_date ? (
                    <Link href={`/invoices/${invoice.invoice_id}/payment-plan`}>
                        Plan de pago
                    </Link>
                ) : null}
                {canEdit ? (
                    <>
                        <Link href={`/invoices/create?clone_from=${invoice.invoice_id}`}>
                            Clonar
                        </Link>
                        <Link href={`/invoices/${invoice.invoice_id}/edit`}>Editar</Link>
                    </>
                ) : null}
                {canDelete ? (
                    <a className="danger-500" onClick={() => setShowModal('delete')}>
                        Borrar
                    </a>
                ) : null}
            </DropdownButton>
            {showModal === 'send' ? (
                <SendNotificationModal
                    invoiceId={invoice.invoice_id}
                    onClose={closeModal}
                    title="Enviar recordatorio"
                />
            ) : showModal === 'payment' ? (
                <Modal title="Registrar pago" onClose={closeModal}>
                    <RecordPaymentFormLoader
                        customer_id={invoice.customer_id}
                        invoices={[invoice]}
                        onSuccess={closeModal}
                    />
                </Modal>
            ) : showModal === 'delete' ? (
                <DeleteInvoiceModal invoice={invoice} closeModal={closeModal} />
            ) : showModal === 'retry-payment' ? (
                <RetryFailedAutoChargePaymentModal
                    invoice={invoice}
                    subscription={subscription!}
                    closeModal={closeModal}
                />
            ) : null}
        </div>
    )
}

const handleCopyPaymentLink = (paymentLink: string) => {
    navigator.clipboard.writeText(paymentLink)
    toast.success('Liga de pago copiada con éxito')
}
